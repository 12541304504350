#contact-main-wrapper{
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-bg{
    z-index: -1;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url('./images/Boreas1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -160px;
    filter: brightness(120%);
}

@media (max-width: 1600px){
    #contact-bg{
        background-position: 0 0;
    }   
}

.contact-form-wrapper{
    height: fit-content;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 2vh;
    padding-bottom: 5vh;
    padding-left: 1%;
    margin-bottom: 8vh;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

#form-first-line1, #form-first-line2{
    width: 100%;
    text-transform: none;
    font-weight: bold;
    font-size: calc(0.8vh + 0.8vw);
    margin-top: 2vh;
}

#message-area, .checkbox-div{
    width: 100%;
    margin-left: 2.5%;
}

#message{
    height: 15vh;
    width: 95%;
    margin-left: 0;
}

.form-text{
    text-transform: uppercase;
}

.form-span{
    color: red;
}

#date-field, #message{
    padding-left: 0;
}

#contact-send{
    width: 30%;
    margin-bottom: 2vh;
    margin-top: 5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(0.8vh + 0.8vw);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: calc(0.9vh + 0.9vw);
}

.checkbox-div{
    margin-top: 2vh;
    width: 100%;
    margin-left: 0;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-checkbox{
    padding: 0;
    margin-left: 2%;
    height: 30px;
    width: 30px;
}

.checkbox-text{
    padding: 0;
    margin: 0;
    margin-left: 3%;
    margin-top: 5px;
    font-size: calc(0.8vh + 0.8vw);
}

#form-sent-popup{
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.801);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#sent-popup{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.753);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

#first-checkbox{
    margin-top: 4vh;
}

#back-button{
    width: fit-content;
    margin-bottom: 2vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(0.8vh + 0.8vw);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    background-color: black;
    color: white;
    font-size: calc(0.9vh + 0.9vw);
}

#date-field{
    padding-left: 3%;
}

@media (max-aspect-ratio: 1/1), (max-width: 600px){
    .contact-form-wrapper{
        width: 90%;
    }

    .form-checkbox{
        height: 18px;
        width: 18px;
    }

    .checkbox-div{
        margin-top: 2vh;
    }
    
    #contact-bg{
        background-image: url('./images/mobilebg1.jpg');
        background-position: 50% 50%;    
    }

    #message{
        height: 10vh;
    }

    .checkbox-text{
        font-size: calc(0.9vh + 0.9vw);
    }
}